export const SearchBoxDatas = [
  // { key: "y62vbr40D9", value: "Carestream CS 3600 Ağız İçi Tarayıcı" },
  // { key: "U2qoPEPPn7", value: "Carestream CS 3700 Ağız İçi Tarayıcı" },
  { key: "0fky5JRUK5", value: "DentaFab Sega 3D Printer" },
  { key: "wF2McAwDbN", value: "SILADENT 3D PRINTER SILAPRINT LCD" },
  { key: "OgKzyq7qlj", value: "SILAMILL 4 EDITION" },
  { key: "9XlMdrhb4i", value: "SILAMILL 5.8 EDITION" },
  { key: "Aq012yPOpC", value: "SILAMILL N4 EDITION" },
  { key: "6AGNtjzh3x", value: "SILAMILL R5" },
  { key: "fxI4W0jv90", value: "SILAMILL T5" },
  { key: "kyBvNYd9Eo", value: "SILAMILL T5 EDITION" },
  { key: "iNp4KdAwi3", value: "SMART OPTİCS VİNYL OPEN AİR" },
  { key: "VWt82QTAxh", value: "SMARTOPTICS VINYL HIGH RESOLUTION" },
  { key: "jJpfLj3OLE", value: "Up3D P53 DENTAL FREZELEME CİHAZI" },
  { key: "aYdRQamh7o", value: "CERAMIC MASTER E20" },
  { key: "OJ14vR3cSM", value: "CERAMICMASTER E1000" },
  { key: "ZK14vR3cSM", value: "CERAMICMASTER E3000" },
  { key: "TsvMva0AMu", value: "CERAMICMASTER PRESS" },
  { key: "gX0OVoBxIK", value: "CERAMICMASTER PRESS PRO" },
  { key: "Qv0OVoBxIK", value: "FIREMAGIC ET400" },
  { key: "nxwVeHnsqI", value: "LC CAST 600T" },
  { key: "fS9WOxabf1", value: "PINPRECISE PP100" },
  { key: "us9Wpxabf1", value: "POLI AM" },
  { key: "u963sxabf1", value: "POLI MAX" },
  { key: "zObKvzOCgk", value: "SLITPRECISE SP100" },
  { key: "AbbKvzOCgk", value: "ZIRCONMASTER S" },
  { key: "JqScIHy2mA", value: "Advance Askılı Diş Ünitesi" },
  { key: "lKBwnEMjYG", value: "ADVANCE Askılı Diş Ünitesi A1" },
  { key: "PnKlBZXX8A", value: "ADVANCE Askılı Diş Ünitesi A3" },
  { key: "I74N2fI3Y8", value: "ADVANCE Kamçılı Diş Ünitesi" },
  { key: "m0A99mC1Fk", value: "ADVANCE Kamçılı Diş Ünitesi A1" },
  { key: "uRnqC40euA", value: "ADVANCE Kamçılı Diş Ünitesi A3" },
  { key: "dxYoNv22oe", value: "Ameliyat Masaları" },
  { key: "d8kYA19AkJ", value: "Jinekolojik ve Ürolojik Masa" },
  { key: "6Xn6aML6Fq", value: "Phantom Dental Simülasyon Çalışma Masası" },
  { key: "AvrFuUwAeb", value: "QUATTRO Askılı Diş Ünitesi" },
  { key: "YNRlN6GndQ", value: "QUATTRO Kamçılı Diş Ünitesi" },
  { key: "3ZTv5PyOQ4", value: "Panoramik" },
  { key: "cTg3vb7U9Y", value: "Tomografi" },
  { key: "epnxnAvWlU", value: "Anemon 00" },
  { key: "2T1pEFn3YS", value: "ANEMON 04" },
  { key: "552Cyt4p01", value: "ANEMON 05" },
  { key: "2xYBmwMbdT", value: "ANEMON 06" },
  { key: "9VIP5Ei9QX", value: "ANEMON 07" },
  { key: "WzVYkyUd2", value: "AZURIT 01" },
  { key: "Xi5k2aJ4ed", value: "AZURIT 02" },
  { key: "hLqXePzk1g", value: "AZURIT 05" },
  { key: "7YfeKNbzL9", value: "KUVARS 01" },
  { key: "AiJ5G4Zj0o", value: "KUVARS MOBİL" },
  { key: "mDrVUOMGai", value: "MOBİL KABİN 02" },
  { key: "BfOBwS5xTj", value: "MOBİL KABİN 03" },
  { key: "Kw9eOocx1F", value: "MOBİL KABİN 04" },
  { key: "r3etcIMTgJ", value: "DFM 75E" },
  { key: "V7RSRfY4BO", value: "PINPRECISE PP100" },
  { key: "SwCYBQQ3TM", value: "SLITPRECISE SP100" },
  { key: "1jmZFqoVSi", value: "Çift Teknisyen Çalışma Masası" },
  { key: "pW7rUCqbGJ", value: "Tekli Teknisyen Çalışma Masası" },
  { key: "lLcw6ysuXl", value: "Zirdent" },
  { key: "dqkPfUvCTD", value: "BLZ" },
];
