import React from "react";
// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import MKBox from "components/MKBox";
// import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";
// import CenteredBlogCard from "examples/Cards/BlogCards/CenteredBlogCard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf } from "@fortawesome/free-regular-svg-icons";

import { Button, Icon } from "@mui/material";
import MKTypography from "components/MKTypography";
// import Slider from "react-slick";
// import Products from "products/ProductDatas/products";

import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import { useTheme } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import Zirdent_1 from "assets/content/Zirdent/1.png";
import Zirdent_2 from "assets/content/Zirdent/2.png";
import Zirdent_3 from "assets/content/Zirdent/3.png";
import Zirdent_4 from "assets/content/Zirdent/4.png";
import Zirdent_5 from "assets/content/Zirdent/5.png";
import Zirdent_6 from "assets/content/Zirdent/6.jpg";
import Zirdent_7 from "assets/content/Zirdent/7.png";
import Zirdent_8 from "assets/content/Zirdent/8.png";
import Zirdent_9 from "assets/content/Zirdent/9.png";
import Zirdent_10 from "assets/content/Zirdent/10.png";
import graph from "assets/content/Zirdent/graph.jpg";
import stc from "assets/content/Zirdent/ST-C.png";
import st from "assets/content/Zirdent/ST.jpg";
import "./line-animation.css";

function Zirdent() {
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };
  // var settings = {
  //   dots: true,
  //   infinite: true,
  //   speed: 200,
  //   slidesToShow: 4,
  //   slidesToScroll: 2,
  //   variableWidth: true,
  //   autoplay: true,
  // };
  // const filteredProducts = Products[8].product.filter((product) => product.model !== "Zirdent");
  return (
    <div>
      <MKBox component="section" py={12}>
        <Container>
          ,
          <Box sx={{ bgcolor: "background.paper" }}>
            <AppBar position="static">
              <Tabs
                value={value}
                onChange={handleChange}
                indicatorColor="secondary"
                textColor="inherit"
                variant="fullWidth"
                aria-label="full width tabs example"
              >
                <Tab label="3D Pro ML" {...a11yProps(0)} />
                <Tab label="STC" {...a11yProps(1)} />
                <Tab label="ST" {...a11yProps(2)} />
              </Tabs>
            </AppBar>
            <SwipeableViews
              axis={theme.direction === "rtl" ? "x-reverse" : "x"}
              index={value}
              onChangeIndex={handleChangeIndex}
            >
              <TabPanel value={value} index={0} dir={theme.direction}>
                <Grid container spacing={3} style={{ marginTop: "50px" }}>
                  <Grid container spacing={3} alignItems="center">
                    <Grid item xs={12} lg={6}>
                      <div className="page-title ">
                        {" "}
                        Yüksek Kaliteli Nano-Seramik Toz ve Çok Katmanlı Gölge Gradasyonu
                      </div>
                      <div className="page-content">
                        Sinocera{"'"}nın yenilikçi toz malzemesi, ISO13485 tarafından yüksek
                        kaliteli olarak onaylanmış ve estetik restorasyon pazarının
                        gereksinimleriyle uyumlu olan 3D Pro ML gibi zirkonya içinde çok katmanlı
                        gölge gradasyonunun sağlanabilmesini sağlayan yüksek performanslı
                        nano-seramik tozunu içeriyor. Şimdi Zirdent, yerleşik şeffaflıkla birlikte
                        çok katmanlı bir görünüm sunuyor.
                      </div>

                      {/* ... */}
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <div style={{ marginBottom: "20px", marginTop: "20px" }}>
                        <img src={Zirdent_1} loading="lazy" width={"100%"} height={"auto"} />
                      </div>{" "}
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <div className="page-title "> Yüksek Bükülme Dayanımı</div>
                      <div className="page-content">
                        3Y TZP stabil tetragonal mikroyapısıyla, 3D Pro ML servikal kısım freze veya
                        öğütme işlemlerine karşı güvenli marjinal dayanım sonuçlarına sahiptir.
                      </div>

                      {/* ... */}
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <div style={{ marginBottom: "20px", marginTop: "20px" }}>
                        <img src={Zirdent_2} loading="lazy" width={"100%"} height={"auto"} />
                      </div>{" "}
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <div className="page-title "> Estetik Performans</div>
                      <div className="page-content">
                        5Y TZP stabil kübik mikroyapısıyla, 3D Pro ML insizal kısmı yüksek
                        geçirgenlik performansına sahip olup doğal dişlere karşı düşük aşınma
                        oranına sahiptir.
                      </div>

                      {/* ... */}
                    </Grid>

                    <Grid item xs={12} lg={6}>
                      <div style={{ marginBottom: "20px", marginTop: "20px" }}>
                        <img src={Zirdent_3} loading="lazy" width={"100%"} height={"auto"} />
                      </div>{" "}
                    </Grid>
                  </Grid>

                  <Grid container spacing={3} alignItems="center">
                    <Grid item xs={12} lg={6}>
                      <div style={{ marginBottom: "20px", marginTop: "20px" }}>
                        <img src={Zirdent_4} loading="lazy" width={"100%"} height={"auto"} />
                      </div>{" "}
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <div className="page-title "> Gradyasyon Çok Katmanlı Teknoloji</div>
                      <div className="page-content">
                        Zirdent 3D Pro ML zirkonyası, geçirgenlik gradasyonu, dayanıklılık
                        gradasyonu ve renk gradasyonu olmak üzere üç gradasyon prensibine dayalı
                        olarak tasarlanmıştır. Doğal dişlerin benzerlik tanımını doğru bir şekilde
                        ifade eden canlı bir protezdir.
                      </div>

                      {/* ... */}
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <div style={{ marginBottom: "20px", marginTop: "20px" }}>
                        <img src={Zirdent_5} loading="lazy" width={"100%"} height={"auto"} />
                      </div>{" "}
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <div className="page-title "> Yüksek Bükülme Dayanımı</div>
                      <div className="page-content">
                        Dikişsiz çok katmanlı teknoloji, geçiş hatları olmadan ve 1,200MPa yüksek
                        bükülme dayanımıyla tüm endikasyonların karşılanmasını sağlar.
                      </div>

                      {/* ... */}
                    </Grid>
                  </Grid>

                  <Grid container spacing={3} alignItems="center">
                    <Grid item xs={12} lg={6}>
                      <div className="page-title "> Hepsi Bir Arada</div>
                      <div className="page-content">
                        <li>Porselen Kaplamalar</li>
                        <li>İnley ve Onley Kaplamalar</li>
                        <li>Kaplama Taşı</li>
                        <li>Ön Kronlar</li>
                        <li>Arka Kronlar</li>
                        <li>İmplant Kronları ve Köprüleri</li>
                        <li>3 Üniteli Köprü</li>
                        <li>Tam Çene Köprüleri</li>
                        <li>Abutman (İmplant Destek Bağlantısı)</li>
                      </div>

                      {/* ... */}
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <div style={{ marginBottom: "20px", marginTop: "20px" }}>
                        <img src={Zirdent_6} loading="lazy" width={"100%"} height={"auto"} />
                      </div>{" "}
                    </Grid>
                  </Grid>

                  <Grid container spacing={3} alignItems="center">
                    <Grid item xs={12} lg={6}>
                      <div style={{ marginBottom: "20px", marginTop: "20px" }}>
                        <img src={Zirdent_7} loading="lazy" width={"100%"} height={"auto"} />
                      </div>
                      <div style={{ marginBottom: "20px", marginTop: "20px" }}>
                        <img src={Zirdent_8} loading="lazy" width={"100%"} height={"auto"} />
                      </div>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <div className="page-title "> Sinterleme Deformasyonu</div>
                      <div className="page-content">
                        Yüksek hassas çekme oranları ve istikrarlı lineer termal genleşme (CTE)
                        değerleri, toz karıştırmadan presleme kalıplamaya kadar tüm sürecin kontrol
                        edilmesiyle elde edilmektedir. Çeşitli katman türleri ve içerideki Ar-Ge ile
                        birleştirilen 24{"'"}ten fazla geniş kapsamlı kalite kontrol yöntemi,
                        Zirdent
                        {"'"}in her zaman iyi performans gösteren zirkonyasına katkıda bulunan ham
                        zirkonya tozu malzemelerinin içeride geliştirilmesini sağlar.
                      </div>

                      {/* ... */}
                    </Grid>
                  </Grid>

                  <Grid container spacing={3} alignItems="center">
                    <Grid item xs={12} lg={6}>
                      <div className="page-title "> Daha İnce Restorasyonlar</div>
                      <div className="page-content">
                        3D Pro ML duvar kalınlığı 0.5mm{"'"}ye kadar inebilir, bu tür bir gövde
                        tabakası, geçirgenliğini daha da artırabilir; veneer endikasyonu veya ön
                        kronlar için önerilen minimum kalınlık.
                      </div>

                      {/* ... */}
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <div style={{ marginBottom: "20px", marginTop: "20px" }}>
                        <img src={Zirdent_9} loading="lazy" width={"100%"} height={"auto"} />
                      </div>{" "}
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <div className="page-title "> Servikal Marjinal Stabilite</div>
                      <div className="page-content">
                        3D Pro ML, 3Y-TZP dörtgen mikroyapısı ile marjinal stabiliteyi gösterir,
                        frezeleme ve öğütme ile güvenli bir kenar uyumu ve daha az çatlama sağlar,
                        bu nedenle 3D Pro ML, daha ince restorasyonlar için güvenilir bir zirkonya
                        malzemesidir.
                      </div>

                      {/* ... */}
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <div style={{ marginBottom: "20px", marginTop: "20px" }}>
                        <img src={Zirdent_10} loading="lazy" width={"100%"} height={"auto"} />
                      </div>{" "}
                    </Grid>
                  </Grid>

                  <Grid container spacing={3} xs={12} lg={12} alignItems="center">
                    <Grid item xs={12} lg={12}>
                      <div className="page-title "> 3D Pro ML Sinterleme Tablosu</div>

                      {/* ... */}
                    </Grid>
                    <Grid item xs={12} lg={12}>
                      <div style={{ marginBottom: "20px", marginTop: "20px" }}>
                        <img src={graph} loading="lazy" width={"100%"} height={"auto"} />
                      </div>{" "}
                    </Grid>
                  </Grid>
                </Grid>
              </TabPanel>
              <TabPanel value={value} index={1} dir={theme.direction}>
                <Grid container spacing={3} style={{ marginTop: "50px" }}>
                  <Grid item xs={12} lg={12}>
                    <div className="page-title "> ST-C Sinterleme Tablosu</div>

                    {/* ... */}
                  </Grid>
                  <Grid item xs={12} lg={12}>
                    <div style={{ marginBottom: "20px", marginTop: "20px" }}>
                      <img src={st} loading="lazy" width={"100%"} height={"auto"} />
                    </div>{" "}
                  </Grid>
                </Grid>
              </TabPanel>
              <TabPanel value={value} index={2} dir={theme.direction}>
                <Grid container spacing={3} style={{ marginTop: "50px" }}>
                  <Grid item xs={12} lg={12}>
                    <div className="page-title "> ST-C Sinterleme Tablosu</div>

                    {/* ... */}
                  </Grid>
                  <Grid item xs={12} lg={12}>
                    <div style={{ marginBottom: "20px", marginTop: "20px" }}>
                      <img src={stc} loading="lazy" width={"100%"} height={"auto"} />
                    </div>{" "}
                  </Grid>
                </Grid>
              </TabPanel>
            </SwipeableViews>
          </Box>
          {/* <div className="page-title" style={{ marginBottom: "10px" }}>
            Benzer Ürünleri İncele
          </div>
          <div style={{ marginTop: "30px !important" }}>
            <Slider {...settings}>
              {filteredProducts.map((product, index) => (
                <div
                  key={index}
                  style={{
                    width: "300px",
                    height: "200px",
                  }}
                >
                  <CenteredBlogCard
                    image={product.img[0]}
                    title={product.model}
                    action={{
                      type: "internal",
                      route: `/urun-detay/${product.id}`,
                      color: "info",
                      label: "Ürünü İncele",
                    }}
                  />
                </div>
              ))}
            </Slider>
          </div> */}
        </Container>
      </MKBox>
    </div>
  );
}

export default Zirdent;

export const ZirdentTitle = () => {
  const pdfDownloader = () => {};
  return (
    <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start" }}>
      <MKBox component="section">
        <Container
          style={{
            backgroundColor: "#0C2540",
            padding: 10,
            borderTopLeftRadius: 15,
            borderTopRightRadius: 15,
          }}
        >
          {" "}
          <Grid container item sx={{ justifyContent: "flex-start" }}>
            <MKTypography
              component="a"
              href="/urunler/klinik-dolaplari"
              target="_blank"
              rel="noreferrer"
              variant="h6"
              color="white"
              fontWeight="regular"
              sx={{
                marginRight: "10px",
                display: "flex",
                alignItems: "center",
                "& .material-icons-round": {
                  fontSize: "1.125rem",
                  transform: `translateX(3px)`,
                  transition: "transform 0.2s cubic-bezier(0.34, 1.61, 0.7, 1.3)",
                },
                "&:hover .material-icons-round, &:focus .material-icons-round": {
                  transform: `translateX(6px)`,
                },
              }}
            >
              Klinik Dolapları
              <Icon sx={{ fontWeight: "bold" }}>arrow_forward</Icon>
            </MKTypography>
            <MKTypography
              component="a"
              target="_blank"
              rel="noreferrer"
              variant="h6"
              color="white"
              fontWeight="regular"
              sx={{
                marginRight: "10px",
                display: "flex",
                alignItems: "center",
                "& .material-icons-round": {
                  fontSize: "1.125rem",
                  transform: `translateX(3px)`,
                  transition: "transform 0.2s cubic-bezier(0.34, 1.61, 0.7, 1.3)",
                },
              }}
            >
              Zirdent
            </MKTypography>
          </Grid>
        </Container>
        <MKTypography
          sx={{ mx: "auto", textAlign: "center", marginTop: 8 }}
          className="productTitle"
        >
          {" "}
          Zirdent
        </MKTypography>
        <Container>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            sx={{ mx: "auto", textAlign: "center" }}
            spacing={1}
            marginBottom={2}
          >
            {" "}
            <Grid item xs={12} lg={12}>
              <div className="page-title"></div>

              <div className="page-content">
                <b>Zirdent</b>, beyaz zirkonya, ön renkli zirkonya ve çok katmanlı zirkonya üretimi
                yapmaktadır. Zirdent, dünya genelinde insanları birleştiren önemli CAD/CAM ürünleri
                ve hizmetleri sunma taahhüdünde bulunacaktır. Zirdent{"'"}in güçlü ve vazgeçilmez
                ürünü olan 3D Pro ML çok katmanlı renk geçişine sahip estetik performansı ile her
                gün olağanüstü sonuçlar elde etmektedir. Dünya genelinde 2 milyondan fazla diş
                vakası oluşturulmuş ve müşterilerden olumlu geri bildirimler alınmıştır.
              </div>
            </Grid>
          </Grid>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: 4,
            }}
          >
            <Button onClick={() => pdfDownloader()} style={{ fontSize: "16px" }}>
              <FontAwesomeIcon icon={faFilePdf} style={{ fontSize: "36px", marginRight: "8px" }} />
              <span style={{ fontSize: "16px", fontWeight: "bold" }}>Ürün Kataloğu</span>
            </Button>
          </div>
        </Container>
      </MKBox>
    </div>
  );
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}
