import React from "react";
// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import MKBox from "components/MKBox";
// import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";
import CenteredBlogCard from "examples/Cards/BlogCards/CenteredBlogCard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf } from "@fortawesome/free-regular-svg-icons";

import { Button, Icon, useMediaQuery, useTheme } from "@mui/material";
import MKTypography from "components/MKTypography";
import Slider from "react-slick";
import Products from "products/ProductDatas/products";
import Blzcontent_1 from "assets/content/BLZ/LS100-scanner-without-post-processing1.png";
import Blzcontent_2 from "assets/content/BLZ/LS100-scanner-without-post-processing2.png";
import Blzcontent_3 from "assets/content/BLZ/lab-scanner-software-feature.png";
import Blzcontent_4 from "assets/content/BLZ/true-color-texture-scan-feature.png";
import wall from "assets/content/BLZ/wall.png";
import "./content.css";

function Blz() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  var settings = {
    dots: true,
    infinite: true,
    speed: 200,
    slidesToShow: isMobile ? 1 : 4,
    slidesToScroll: isMobile ? 1 : 2,
    variableWidth: true,
    autoplay: true,
  };
  const filteredProducts = Products[1].product.filter((product) => product.model !== "BLZ");
  return (
    <div>
      <MKBox component="section" py={12}>
        <Container>
          <Grid>
            <Grid container spacing={3}>
              <Grid item xs={12} lg={6} style={{ padding: "50px" }}>
                {/* Başlık */}
                <div className="page-title custom-header">POST İŞLEME ZAMANI YOK </div>
                <div className="page-title custom-header">ARTAN VERİMLİLİK </div>
                <div className="page-content">
                  Gelişmiş yapay zeka teknolojisi kullanılarak, BLZ Dental LS100 laboratuvar
                  tarayıcısı dünyanın ilk post işleme gerektirmeyen laboratuvar tarayıcısı haline
                  geliyor. Kendi geliştirdiği sezgisel tarama yazılımı ile entegre edilen
                  laboratuvar tarayıcısı, tarama sürecini sadece 2 dakikada tamamlıyor, bu da dental
                  iş akışının verimliliğini %50 artırıyor.
                </div>

                {/* ... */}
              </Grid>
              <Grid item xs={12} lg={6} style={{ padding: "50px" }}>
                <div style={{ marginBottom: "20px", marginTop: "20px" }}>
                  <img src={Blzcontent_1} loading="lazy" width={"100%"} height={"auto"} />
                </div>{" "}
                <div style={{ marginBottom: "20px", marginTop: "20px" }}>
                  <img src={Blzcontent_2} loading="lazy" width={"100%"} height={"auto"} />
                </div>
              </Grid>
            </Grid>

            <Grid container spacing={3}>
              <Grid item xs={12} lg={6} style={{ padding: "50px" }}>
                <div style={{ marginBottom: "20px", marginTop: "20px" }}>
                  <img src={Blzcontent_4} loading="lazy" width={"100%"} height={"auto"} />
                </div>
              </Grid>
              <Grid item xs={12} lg={6} style={{ padding: "50px" }}>
                <div className="page-title custom-header">HASSAS GERÇEK RENK TARAMASI </div>

                <li className="page-content">
                  Siyah işaretlemeler deliksiz bir şekilde görülebilir.
                </li>
                <li className="page-content">RPD için el yazısı dokusu.</li>
                <li className="page-content">Döküm kenarındaki kırmızı işaretleme.</li>
              </Grid>
            </Grid>

            <Grid container spacing={3}>
              <Grid item xs={12} lg={6} style={{ padding: "50px" }}>
                {/* Başlık */}
                <div className="page-title custom-header">HASSAS TARAMA İÇİN AKILLI YAZILIM </div>
                <div className="page-content">
                  Hassas Tarama İçin Akıllı Yazılım Laboratuvar tarayıcısı, gelişmiş 3D görüntüleme
                  sistemini ve algoritma teknolojisini bir araya getiren sezgisel tarama yazılımı
                  ile birlikte kullanılır ve kesin dijital izlenim verileri oluşturur. Donanım ve
                  yazılımın entegrasyonu, mükemmel tarama sonucunu sağlar.
                </div>

                {/* ... */}
              </Grid>
              <Grid item xs={12} lg={6} style={{ padding: "50px" }}>
                <div style={{ marginBottom: "20px", marginTop: "20px" }}>
                  <img src={Blzcontent_3} loading="lazy" width={"100%"} height={"auto"} />
                </div>
              </Grid>
            </Grid>

            <Grid container spacing={3}>
              <Grid item xs={12} lg={12} style={{ padding: "50px" }}>
                <div style={{ marginBottom: "20px", marginTop: "20px" }}>
                  <img src={wall} loading="lazy" width={"100%"} height={"auto"} />
                </div>
              </Grid>
            </Grid>

            <Grid item xs={12} lg={4} sx={{ ml: "auto", mt: { xs: 3, lg: 0 } }}></Grid>
          </Grid>
          <div className="page-title" style={{ marginBottom: "10px" }}>
            Benzer Ürünleri İncele
          </div>

          <div style={{ marginTop: "30px !important" }}>
            <Slider {...settings}>
              {filteredProducts.map((product, index) => (
                <div
                  key={index}
                  style={{
                    width: "300px",
                    height: "200px",
                  }}
                >
                  <CenteredBlogCard
                    image={product.img[0]}
                    title={product.model}
                    action={{
                      type: "internal",
                      route: `/urun-detay/${product.id}`,
                      color: "info",
                      label: "Ürünü İncele",
                    }}
                  />
                </div>
              ))}
            </Slider>
          </div>
        </Container>
      </MKBox>
    </div>
  );
}

export default Blz;

export const BlzTitle = () => {
  const pdfDownloader = () => {};
  return (
    <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start" }}>
      <MKBox component="section">
        <Container
          style={{
            backgroundColor: "#0C2540",
            padding: 10,
            borderTopLeftRadius: 15,
            borderTopRightRadius: 15,
          }}
        >
          {" "}
          <Grid container item sx={{ justifyContent: "flex-start" }}>
            <MKTypography
              component="a"
              href="/urunler/cad-cam-sistemleri"
              target="_blank"
              rel="noreferrer"
              variant="h6"
              color="white"
              fontWeight="regular"
              sx={{
                marginRight: "10px",
                display: "flex",
                alignItems: "center",
                "& .material-icons-round": {
                  fontSize: "1.125rem",
                  transform: `translateX(3px)`,
                  transition: "transform 0.2s cubic-bezier(0.34, 1.61, 0.7, 1.3)",
                },
                "&:hover .material-icons-round, &:focus .material-icons-round": {
                  transform: `translateX(6px)`,
                },
              }}
            >
              CAD/CAM Sistemleri
              <Icon sx={{ fontWeight: "bold" }}>arrow_forward</Icon>
            </MKTypography>
            <MKTypography
              component="a"
              href="/urunler/cad-cam-sistemleri/model-tarayicilar"
              target="_blank"
              rel="noreferrer"
              variant="h6"
              color="white"
              fontWeight="regular"
              sx={{
                marginRight: "10px",
                display: "flex",
                alignItems: "center",
                "& .material-icons-round": {
                  fontSize: "1.125rem",
                  transform: `translateX(3px)`,
                  transition: "transform 0.2s cubic-bezier(0.34, 1.61, 0.7, 1.3)",
                },
                "&:hover .material-icons-round, &:focus .material-icons-round": {
                  transform: `translateX(6px)`,
                },
              }}
            >
              Model Tarayıcılar
              <Icon sx={{ fontWeight: "bold" }}>arrow_forward</Icon>
            </MKTypography>
            <MKTypography
              component="a"
              target="_blank"
              rel="noreferrer"
              variant="h6"
              color="white"
              fontWeight="regular"
              sx={{
                marginRight: "10px",
                display: "flex",
                alignItems: "center",
                "& .material-icons-round": {
                  fontSize: "1.125rem",
                  transform: `translateX(3px)`,
                  transition: "transform 0.2s cubic-bezier(0.34, 1.61, 0.7, 1.3)",
                },
              }}
            >
              BLZ
            </MKTypography>
          </Grid>
        </Container>
        <MKTypography
          sx={{ mx: "auto", textAlign: "center", marginTop: 8 }}
          className="productTitle"
        >
          {" "}
          BLZ
        </MKTypography>
        <Container>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            sx={{ mx: "auto", textAlign: "center" }}
            spacing={1}
            marginBottom={2}
          >
            {" "}
            <Grid item xs={12} lg={12}>
              <div className="page-title">YÜKSEK VERİM İÇİN HIZLI TARAMA</div>
              <div className="page-content ">
                Yüksek kaliteli birim ve akıllı yazılım sayesinde, dental laboratuvar tarayıcısı
                geniş bir model yelpesini hızlı bir şekilde tarayabilir. Tarama verimliliği büyük
                ölçüde arttı!
              </div>
              <br />
            </Grid>
          </Grid>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: 4,
            }}
          >
            <Button onClick={() => pdfDownloader()} style={{ fontSize: "16px" }}>
              <FontAwesomeIcon icon={faFilePdf} style={{ fontSize: "36px", marginRight: "8px" }} />
              <span style={{ fontSize: "16px", fontWeight: "bold" }}>Ürün Kataloğu</span>
            </Button>
          </div>
        </Container>
      </MKBox>
    </div>
  );
};
