// import Agizicitarayicilar1 from "assets/images/products-images/agiz-ici-tarayicilar/carestream-cs-3600/1.jpg";
// import Agizicitarayicilar2 from "assets/images/products-images/agiz-ici-tarayicilar/carestream-cs-3600/2.jpg";
// import Agizicitarayicilar3 from "assets/images/products-images/agiz-ici-tarayicilar/carestream-cs-3600/5.jpg";
// import Agizicitarayicilar4 from "assets/images/products-images/agiz-ici-tarayicilar/carestream-cs-3600/6.jpg";
// import Agizicitarayicilar5 from "assets/images/products-images/agiz-ici-tarayicilar/carestream-cs-3600/carestream-cs-3600-agiz-ici-tarayici-gorsel.jpg";
// import Agizicitarayicilar6 from "assets/images/products-images/agiz-ici-tarayicilar/carestream-cs-3600/3-scaled.jpg";
// import Agizicitarayicilar7 from "assets/images/products-images/agiz-ici-tarayicilar/carestream-cs-3700/1-1.jpg";
// import Agizicitarayicilar8 from "assets/images/products-images/agiz-ici-tarayicilar/carestream-cs-3700/3-1.jpg";
// import Agizicitarayicilar9 from "assets/images/products-images/agiz-ici-tarayicilar/carestream-cs-3700/7-1.jpg";
// import Agizicitarayicilar10 from "assets/images/products-images/agiz-ici-tarayicilar/carestream-cs-3700/carestream-cs-3700-agiz-ici-tarayici-gorsel.jpg";

import linkedin1024393 from "assets/images/products-images/cad-cam-sistemleri/3d-printer/dentafab-sega-3d-printer/linkedin-1024x393.jpeg";
import segasidejpg from "assets/images/products-images/cad-cam-sistemleri/3d-printer/dentafab-sega-3d-printer/sega-side.jpg";
import Segadouble from "assets/images/products-images/cad-cam-sistemleri/3d-printer/dentafab-sega-3d-printer/Sega-double.jpg";
import Segafront from "assets/images/products-images/cad-cam-sistemleri/3d-printer/dentafab-sega-3d-printer/Sega-front.jpg";
import Segamodel from "assets/images/products-images/cad-cam-sistemleri/3d-printer/dentafab-sega-3d-printer/Segamodel.jpg";

import SILADENT3DPRINTERSILAPRINTLCD from "assets/images/products-images/cad-cam-sistemleri/3d-printer/siladent-3d-printer-silaprint-lcd/SilaPrint-LCD.jpg";

import silamill4edition1 from "assets/images/products-images/cad-cam-sistemleri/kaziyicilar/silamill-4-edition/2-2.jpg";
import silamill4edition2 from "assets/images/products-images/cad-cam-sistemleri/kaziyicilar/silamill-4-edition/Silamill-4.jpg";

import silamill58edition1 from "assets/images/products-images/cad-cam-sistemleri/kaziyicilar/silamill-5_8-edition/2-3.jpg";
import silamill58edition2 from "assets/images/products-images/cad-cam-sistemleri/kaziyicilar/silamill-5_8-edition/3-2.jpg";
import silamill58edition3 from "assets/images/products-images/cad-cam-sistemleri/kaziyicilar/silamill-5_8-edition/5-2.jpg";
import silamill58edition4 from "assets/images/products-images/cad-cam-sistemleri/kaziyicilar/silamill-5_8-edition/6-2-768x512.jpg";
import silamill58edition5 from "assets/images/products-images/cad-cam-sistemleri/kaziyicilar/silamill-5_8-edition/8.jpg";
import silamill58edition6 from "assets/images/products-images/cad-cam-sistemleri/kaziyicilar/silamill-5_8-edition/10.jpg";
import silamill58edition7 from "assets/images/products-images/cad-cam-sistemleri/kaziyicilar/silamill-5_8-edition/11-768x558.jpg";
import silamill58edition8 from "assets/images/products-images/cad-cam-sistemleri/kaziyicilar/silamill-5_8-edition/12-600x435.jpg";
import silamill58edition9 from "assets/images/products-images/cad-cam-sistemleri/kaziyicilar/silamill-5_8-edition/13-600x436.jpg";
import silamill58edition10 from "assets/images/products-images/cad-cam-sistemleri/kaziyicilar/silamill-5_8-edition/SilaMill-5.8-Edition.jpg";

import silamilln4edition1 from "assets/images/products-images/cad-cam-sistemleri/kaziyicilar/silamill-n4-edition/8-1.jpg";
import silamilln4edition2 from "assets/images/products-images/cad-cam-sistemleri/kaziyicilar/silamill-n4-edition/9-1.jpg";
import silamilln4edition3 from "assets/images/products-images/cad-cam-sistemleri/kaziyicilar/silamill-n4-edition/10-1.jpg";
import silamilln4edition4 from "assets/images/products-images/cad-cam-sistemleri/kaziyicilar/silamill-n4-edition/11.png";
import silamilln4edition5 from "assets/images/products-images/cad-cam-sistemleri/kaziyicilar/silamill-n4-edition/12-1.jpg";
import silamilln4edition6 from "assets/images/products-images/cad-cam-sistemleri/kaziyicilar/silamill-n4-edition/SilaMill-N4-edition-links.jpg";

import silamillr5_1 from "assets/images/products-images/cad-cam-sistemleri/kaziyicilar/silamill-r5/1-1-600x619.jpg";
import silamillr5_2 from "assets/images/products-images/cad-cam-sistemleri/kaziyicilar/silamill-r5/3-600x356.jpg";
import silamillr5_3 from "assets/images/products-images/cad-cam-sistemleri/kaziyicilar/silamill-r5/4-600x600.png";
import silamillr5_4 from "assets/images/products-images/cad-cam-sistemleri/kaziyicilar/silamill-r5/5.jpg";
import silamillr5_5 from "assets/images/products-images/cad-cam-sistemleri/kaziyicilar/silamill-r5/6-600x600.png";

import silamillt5_1 from "assets/images/products-images/cad-cam-sistemleri/kaziyicilar/silamill-t5/2-5.jpg";
import silamillt5_2 from "assets/images/products-images/cad-cam-sistemleri/kaziyicilar/silamill-t5/3-4.jpg";
import silamillt5_3 from "assets/images/products-images/cad-cam-sistemleri/kaziyicilar/silamill-t5/4-4.jpg";
import silamillt5_4 from "assets/images/products-images/cad-cam-sistemleri/kaziyicilar/silamill-t5/5-5.jpg";
import silamillt5_5 from "assets/images/products-images/cad-cam-sistemleri/kaziyicilar/silamill-t5/6-4.jpg";
import silamillt5_6 from "assets/images/products-images/cad-cam-sistemleri/kaziyicilar/silamill-t5/10-2-600x400.jpg";
import silamillt5_7 from "assets/images/products-images/cad-cam-sistemleri/kaziyicilar/silamill-t5/Silamill-T5-gorsel.jpg";

import silamillt5edition_1 from "assets/images/products-images/cad-cam-sistemleri/kaziyicilar/silamill-t5-edition/SILAMILL-T5-EDITION-gorsel.jpg";

import silamillz4edition_1 from "assets/images/products-images/cad-cam-sistemleri/kaziyicilar/silamill-z4-edition/2-7.jpg";
import silamillz4edition_2 from "assets/images/products-images/cad-cam-sistemleri/kaziyicilar/silamill-z4-edition/3-6.jpg";
import silamillz4edition_3 from "assets/images/products-images/cad-cam-sistemleri/kaziyicilar/silamill-z4-edition/4-5.jpg";
import silamillz4edition_4 from "assets/images/products-images/cad-cam-sistemleri/kaziyicilar/silamill-z4-edition/5-6-600x400.jpg";
import silamillz4edition_5 from "assets/images/products-images/cad-cam-sistemleri/kaziyicilar/silamill-z4-edition/6-6.jpg";
import silamillz4edition_6 from "assets/images/products-images/cad-cam-sistemleri/kaziyicilar/silamill-z4-edition/SilaMill_Z4.jpg";

import up3d_1 from "assets/images/products-images/cad-cam-sistemleri/kaziyicilar/up3d-p53-dental-frezleme-cihazi/P53-1.jpg";
import up3d_2 from "assets/images/products-images/cad-cam-sistemleri/kaziyicilar/up3d-p53-dental-frezleme-cihazi/P53-2.jpg";
import up3d_3 from "assets/images/products-images/cad-cam-sistemleri/kaziyicilar/up3d-p53-dental-frezleme-cihazi/P53-3.jpg";
import up3d_4 from "assets/images/products-images/cad-cam-sistemleri/kaziyicilar/up3d-p53-dental-frezleme-cihazi/P53-4.jpg";
import up3d_5 from "assets/images/products-images/cad-cam-sistemleri/kaziyicilar/up3d-p53-dental-frezleme-cihazi/P53-5.jpg";
import up3d_6 from "assets/images/products-images/cad-cam-sistemleri/kaziyicilar/up3d-p53-dental-frezleme-cihazi/P53-6.jpg";
import up3d_7 from "assets/images/products-images/cad-cam-sistemleri/kaziyicilar/up3d-p53-dental-frezleme-cihazi/P53-7.jpg";
import up3d_8 from "assets/images/products-images/cad-cam-sistemleri/kaziyicilar/up3d-p53-dental-frezleme-cihazi/P53-8.jpg";
import up3d_9 from "assets/images/products-images/cad-cam-sistemleri/kaziyicilar/up3d-p53-dental-frezleme-cihazi/UP3D-P53-Gorsel.jpg";

import svhr_1 from "assets/images/products-images/cad-cam-sistemleri/model-tarayicilar/smaroptics-vinyl-high-resolution/2-8-600x417.jpg";
import svhr_2 from "assets/images/products-images/cad-cam-sistemleri/model-tarayicilar/smaroptics-vinyl-high-resolution/3-600x792.png";
import svhr_3 from "assets/images/products-images/cad-cam-sistemleri/model-tarayicilar/smaroptics-vinyl-high-resolution/4-1-600x717.png";
import svhr_4 from "assets/images/products-images/cad-cam-sistemleri/model-tarayicilar/smaroptics-vinyl-high-resolution/5-600x741.png";
import svhr_5 from "assets/images/products-images/cad-cam-sistemleri/model-tarayicilar/smaroptics-vinyl-high-resolution/Vinyl_High_Resolution.jpg";
import svhr_6 from "assets/images/products-images/cad-cam-sistemleri/model-tarayicilar/smaroptics-vinyl-high-resolution/Vinyl_open_air.jpg";

import sovoa_1 from "assets/images/products-images/cad-cam-sistemleri/model-tarayicilar/smart-optics-vinyl-open-air/Vinyl_open_air.jpg";

import e20_1 from "assets/images/products-images/dental-firinlar/ceramic-processing-porselen-firinlari/ceramic-matser-e20/E-20-1.jpg";
import e20_2 from "assets/images/products-images/dental-firinlar/ceramic-processing-porselen-firinlari/ceramic-matser-e20/E-20-2.jpg";
import e20_3 from "assets/images/products-images/dental-firinlar/ceramic-processing-porselen-firinlari/ceramic-matser-e20/E-20-3.jpg";

import e1000_1 from "assets/images/products-images/dental-firinlar/ceramic-processing-porselen-firinlari/ceramicmatser-e1000/E-1000-1.jpg";
import e1000_2 from "assets/images/products-images/dental-firinlar/ceramic-processing-porselen-firinlari/ceramicmatser-e1000/E-1000-2.jpg";
import e1000_3 from "assets/images/products-images/dental-firinlar/ceramic-processing-porselen-firinlari/ceramicmatser-e1000/E-1000-3.jpg";

import e3000_1 from "assets/images/products-images/dental-firinlar/ceramic-processing-porselen-firinlari/ceramicmatser-e3000/E-3000-NEW-1-Черни.jpg";
import e3000_2 from "assets/images/products-images/dental-firinlar/ceramic-processing-porselen-firinlari/ceramicmatser-e3000/E-3000-NEW-2-Черни.jpg";
import e3000_3 from "assets/images/products-images/dental-firinlar/ceramic-processing-porselen-firinlari/ceramicmatser-e3000/E-3000-NEW-3-Черни.jpg";

import erress_1 from "assets/images/products-images/dental-firinlar/press-firinlari/ceramicmatser-press/E-Rress-1.jpg";
import erress_2 from "assets/images/products-images/dental-firinlar/press-firinlari/ceramicmatser-press/E-Rress-2.jpg";
import erress_3 from "assets/images/products-images/dental-firinlar/press-firinlari/ceramicmatser-press/E-Rress-3.jpg";

import presspro_1 from "assets/images/products-images/dental-firinlar/press-firinlari/ceramicmatser-press-pro/CeramicMaster-Press-Pro-1.jpg";
import presspro_2 from "assets/images/products-images/dental-firinlar/press-firinlari/ceramicmatser-press-pro/CeramicMaster-Press-Pro-2.jpg";
import presspro_3 from "assets/images/products-images/dental-firinlar/press-firinlari/ceramicmatser-press-pro/CeramicMaster-Press-Pro.jpg";

import firemagicer400_1 from "assets/images/products-images/dental-firinlar/dokum-firinlari/firemagic-et400/ET400.jpg";
import firemagicer400_2 from "assets/images/products-images/dental-firinlar/dokum-firinlari/firemagic-et400/Firemagic-FM4.jpg";

import lccast_1 from "assets/images/products-images/dental-firinlar/dokum-firinlari/lc-cast-600t/LC-Cast-600-T-1.jpg";
import lccast_2 from "assets/images/products-images/dental-firinlar/dokum-firinlari/lc-cast-600t/LC-Cast-600-T-2.jpg";
import lccast_3 from "assets/images/products-images/dental-firinlar/dokum-firinlari/lc-cast-600t/LC-Cast-600-T-3.jpg";
import lccast_4 from "assets/images/products-images/dental-firinlar/dokum-firinlari/lc-cast-600t/LC-Cast-600-T-4.jpg";
import lccast_5 from "assets/images/products-images/dental-firinlar/dokum-firinlari/lc-cast-600t/LC-Cast-600T.jpg";

import polyam_1 from "assets/images/products-images/dental-firinlar/dokum-firinlari/poli-am/Poly-AM.jpg";
import polyam_2 from "assets/images/products-images/dental-firinlar/dokum-firinlari/poli-am/Poly-AM_1.jpg";

import polymax_1 from "assets/images/products-images/dental-firinlar/dokum-firinlari/poli-max/Poly-MAX-1.jpg";
import polymax_2 from "assets/images/products-images/dental-firinlar/dokum-firinlari/poli-max/Poly-MAX-2.jpg";

import zms_1 from "assets/images/products-images/dental-firinlar/zirkon-sinterleme-firinlari/zirconmatser-s/Z.M.S-1.jpg";

import aadu_1 from "assets/images/products-images/dis-uniteleri/advance-askili-dis-unitesi/advance-askili.jpg";
import aadu_2 from "assets/images/products-images/dis-uniteleri/advance-askili-dis-unitesi/advande-yeni-yatay-tr-01.jpg";

import aadu_a1_1 from "assets/images/products-images/dis-uniteleri/advance-askili-dis-unitesi-a1/advance-askili-dus-unit-a1.jpg";
import aadu_a1_2 from "assets/images/products-images/dis-uniteleri/advance-askili-dis-unitesi-a1/advande-yeni-yatay-tr-01.jpg";

import aadu_a3_1 from "assets/images/products-images/dis-uniteleri/advance-askili-dis-unitesi-a3/advance-askili-dis-unit-A3.jpg";
import aadu_a3_2 from "assets/images/products-images/dis-uniteleri/advance-askili-dis-unitesi-a3/advande-yeni-yatay-tr-01.jpg";

import akdu_1 from "assets/images/products-images/dis-uniteleri/advance-kamçılı-dis-unitesi/advancekamcili.jpg";
import akdu_2 from "assets/images/products-images/dis-uniteleri/advance-kamçılı-dis-unitesi/advande-yeni-yatay-tr-01.jpg";

import akdu_a1_1 from "assets/images/products-images/dis-uniteleri/advance-kamçılı-dis-unitesi-a1/ADVANCE-KAMCILI-DIS-UNITI-A1.jpg";

import akdu_a3_1 from "assets/images/products-images/dis-uniteleri/advance-kamçılı-dis-unitesi-a3/advancekamcili-a3-655x504-1.jpg";

import am_1 from "assets/images/products-images/dis-uniteleri/ameliyat-masalari/10.jpg";
import am_2 from "assets/images/products-images/dis-uniteleri/ameliyat-masalari/11.jpg";
import am_3 from "assets/images/products-images/dis-uniteleri/ameliyat-masalari/6.jpg";
import am_4 from "assets/images/products-images/dis-uniteleri/ameliyat-masalari/9.jpg";

import jvum_1 from "assets/images/products-images/dis-uniteleri/jinekolojik-ve-urolojik-masa/1.jpg";
import jvum_2 from "assets/images/products-images/dis-uniteleri/jinekolojik-ve-urolojik-masa/2_1.jpg";

import pdscm_1 from "assets/images/products-images/dis-uniteleri/phantom-dental-simulasyon-calisma-masasi/PH01-1.jpg";
import pdscm_2 from "assets/images/products-images/dis-uniteleri/phantom-dental-simulasyon-calisma-masasi/PH01.jpg";

import qadu_1 from "assets/images/products-images/dis-uniteleri/quattro-askili-dis-unitesi/quattro-askili-655x504-1.jpg";
import qadu_2 from "assets/images/products-images/dis-uniteleri/quattro-askili-dis-unitesi/quattro-yeni-yatay-tr-01.jpg";

import qkdu_1 from "assets/images/products-images/dis-uniteleri/quattro-kamcili-dis-unitesi/quattro-kamcili.jpg";
import qkdu_2 from "assets/images/products-images/dis-uniteleri/quattro-kamcili-dis-unitesi/quattro-yeni-yatay-tr-01.jpg";

import grntlnm from "assets/images/products-images/görüntülenme-sistemleri/panoramik/tomografi-blog.jpg";

import anemon00_1 from "assets/images/products-images/klinik-dolapları/anemon-00/anemonAN00-1.jpg";
import anemon00_2 from "assets/images/products-images/klinik-dolapları/anemon-00/anemonAN00-2.jpg";
import anemon00_3 from "assets/images/products-images/klinik-dolapları/anemon-00/anemonAN00.jpg";

import anemon04_1 from "assets/images/products-images/klinik-dolapları/anemon-04/AN04-2.jpg";

import anemon05_1 from "assets/images/products-images/klinik-dolapları/anemon-05/AN005-1.jpg";
import anemon05_2 from "assets/images/products-images/klinik-dolapları/anemon-05/AN005-2.jpg";

import anemon06_1 from "assets/images/products-images/klinik-dolapları/anemon-06/AN06-1.jpg";
import anemon06_2 from "assets/images/products-images/klinik-dolapları/anemon-06/AN06-2.jpg";

import anemon07_1 from "assets/images/products-images/klinik-dolapları/anemon-07/AN07-1-600x462.jpg";
import anemon07_2 from "assets/images/products-images/klinik-dolapları/anemon-07/AN07.jpg";

import azurit_01 from "assets/images/products-images/klinik-dolapları/azurit-01/AZ01-1.jpg";
import azurit_02 from "assets/images/products-images/klinik-dolapları/azurit-01/AZ01-2-600x462.jpg";

import azurit02_1 from "assets/images/products-images/klinik-dolapları/azurit-02/AZ02-1-600x462.jpg";
import azurit02_2 from "assets/images/products-images/klinik-dolapları/azurit-02/AZ02-2.jpg";

import azurit05_1 from "assets/images/products-images/klinik-dolapları/azurit-05/AZ05-1-600x462.jpg";
import azurit05_2 from "assets/images/products-images/klinik-dolapları/azurit-05/AZ05-2-600x462.jpg";
import azurit05_3 from "assets/images/products-images/klinik-dolapları/azurit-05/AZ05-3.jpg";

import kuvars01_1 from "assets/images/products-images/klinik-dolapları/kuvars-01/KV01-1.jpg";
import kuvars01_2 from "assets/images/products-images/klinik-dolapları/kuvars-01/kv01-2.jpg";

import kuvarsmobil_1 from "assets/images/products-images/klinik-dolapları/kuvars-mobil/KVM-655x504-1.jpg";
import kuvarsmobil_2 from "assets/images/products-images/klinik-dolapları/kuvars-mobil/kvm-01-600x462.jpg";

import mobilkabin02_1 from "assets/images/products-images/klinik-dolapları/mobil-kabin-02/MB02-1-655x504-1.jpg";
import mobilkabin02_2 from "assets/images/products-images/klinik-dolapları/mobil-kabin-02/MB02-600x462.jpg";

import mobilkabin03_1 from "assets/images/products-images/klinik-dolapları/mobil-kabin-03/MB03-1-1-600x462.jpg";
import mobilkabin03_2 from "assets/images/products-images/klinik-dolapları/mobil-kabin-03/MB03.jpg";

import mobilkabin04_1 from "assets/images/products-images/klinik-dolapları/mobil-kabin-04/MB04-1.jpg";
import mobilkabin04_2 from "assets/images/products-images/klinik-dolapları/mobil-kabin-04/MB04-600x462.jpg";

import dfm_1 from "assets/images/products-images/laboratuvar-ekipmanlari/dfm-75e/DFM-75E.jpg";
import dfm_2 from "assets/images/products-images/laboratuvar-ekipmanlari/dfm-75e/DFM-75E1-600x651.jpg";

import pinprecise_1 from "assets/images/products-images/laboratuvar-ekipmanlari/pinprecise-pp100/PP100.jpg";
import pinprecise_2 from "assets/images/products-images/laboratuvar-ekipmanlari/pinprecise-pp100/Pin-3.jpg";

import slitprecise_1 from "assets/images/products-images/laboratuvar-ekipmanlari/slitprecise-sp100/9063.png";
import slitprecise_2 from "assets/images/products-images/laboratuvar-ekipmanlari/slitprecise-sp100/Rezachka-1.jpg";
import slitprecise_3 from "assets/images/products-images/laboratuvar-ekipmanlari/slitprecise-sp100/Rezachka-2.jpg";
import slitprecise_4 from "assets/images/products-images/laboratuvar-ekipmanlari/slitprecise-sp100/Rezachka-3.jpg";

import ctcm_1 from "assets/images/products-images/teknisyen-masaları/cift-teknisyen-calisma-masasi/GPW02.jpg";

import ttcm_1 from "assets/images/products-images/teknisyen-masaları/tekli-teknisyen-calisma-masasi/GPW02.jpg";

import blz_1 from "assets/images/products-images/blz/BLXZPNG.png";
import blz_2 from "assets/images/products-images/blz/LS100-1.jpg";
import blz_3 from "assets/images/products-images/blz/LS100-2.png";
import blz_4 from "assets/images/products-images/blz/LS100-3.png";
import blz_5 from "assets/images/products-images/blz/LS100-4.png";
import blz_6 from "assets/images/products-images/blz/Poster of LS100.jpg";

import zirdent_1 from "assets/images/products-images/zirdent/1.jpg";
import zirdent_2 from "assets/images/products-images/zirdent/2.jpg";
import zirdent_3 from "assets/images/products-images/zirdent/3.jpg";
import zirdent_4 from "assets/images/products-images/zirdent/4.jpg";
import zirdent_5 from "assets/images/products-images/zirdent/5.jpg";
import zirdent_6 from "assets/images/products-images/zirdent/6.jpg";

const Products = [
  {
    index: 0,
    category: "agiz-ici-tarayicilar",
    product: [
      // {
      //   id: "y62vbr40D9",
      //   model: "Carestream CS 3600 Ağız İçi Tarayıcı",
      //   img: [
      //     Agizicitarayicilar5,
      //     Agizicitarayicilar1,
      //     Agizicitarayicilar2,
      //     Agizicitarayicilar3,
      //     Agizicitarayicilar4,
      //     Agizicitarayicilar6,
      //   ],
      // },
      // {
      //   id: "U2qoPEPPn7",
      //   model: "Carestream CS 3700 Ağız İçi Tarayıcı",
      //   img: [Agizicitarayicilar9, Agizicitarayicilar7, Agizicitarayicilar8, Agizicitarayicilar10],
      // },
    ],
  },
  {
    index: 1,
    category: "cad-cam-sistemleri",
    product: [
      {
        code: 1,
        id: "0fky5JRUK5",
        model: "DentaFab Sega 3D Printer",
        img: [Segadouble, linkedin1024393, Segamodel, Segafront, segasidejpg],
      },
      {
        code: 1,
        id: "wF2McAwDbN",
        model: "SILADENT 3D PRINTER SILAPRINT LCD",
        img: [SILADENT3DPRINTERSILAPRINTLCD],
      },
      {
        code: 2,
        id: "OgKzyq7qlj",
        model: "SILAMILL 4 EDITION",
        img: [silamill4edition2, silamill4edition1],
      },
      {
        code: 2,
        id: "9XlMdrhb4i",
        model: "SILAMILL 5.8 EDITION",
        img: [
          silamill58edition10,
          silamill58edition1,
          silamill58edition2,
          silamill58edition3,
          silamill58edition4,
          silamill58edition5,
          silamill58edition6,
          silamill58edition7,
          silamill58edition8,
          silamill58edition9,
        ],
      },
      {
        code: 2,
        id: "Aq012yPOpC",
        model: "SILAMILL N4 EDITION",
        img: [
          silamilln4edition6,
          silamilln4edition1,
          silamilln4edition2,
          silamilln4edition3,
          silamilln4edition4,
          silamilln4edition5,
        ],
      },
      {
        code: 2,
        id: "6AGNtjzh3x",
        model: "SILAMILL R5",
        img: [silamillr5_1, silamillr5_2, silamillr5_3, silamillr5_4, silamillr5_5],
      },
      {
        code: 2,
        id: "fxI4W0jv90",
        model: "SILAMILL T5",
        img: [
          silamillt5_7,
          silamillt5_1,
          silamillt5_2,
          silamillt5_3,
          silamillt5_4,
          silamillt5_5,
          silamillt5_6,
        ],
      },
      { code: 2, id: "kyBvNYd9Eo", model: "SILAMILL T5 EDITION", img: [silamillt5edition_1] },
      {
        code: 2,
        id: "iNp4KdAwi3",
        model: "SILAMILL Z4",
        img: [
          silamillz4edition_6,
          silamillz4edition_1,
          silamillz4edition_2,
          silamillz4edition_3,
          silamillz4edition_4,
          silamillz4edition_5,
        ],
      },
      {
        code: 3,
        id: "6ckA2QoXgA",
        model: "SMART OPTİCS VİNYL OPEN AİR",
        img: [sovoa_1],
      },
      {
        code: 3,
        id: "dqkPfUvCTD",
        model: "BLZ",
        img: [blz_1, blz_2, blz_3, blz_4, blz_5, blz_6],
      },
      {
        code: 3,
        id: "VWt82QTAxh",
        model: "SMARTOPTICS VINYL HIGH RESOLUTION",
        img: [svhr_5, svhr_1, svhr_2, svhr_3, svhr_4, svhr_6],
      },
      {
        code: 2,
        id: "jJpfLj3OLE",
        model: "Up3D P53 DENTAL FREZELEME CİHAZI",
        img: [up3d_9, up3d_1, up3d_2, up3d_3, up3d_4, up3d_5, up3d_6, up3d_7, up3d_8],
      },
    ],
  },
  {
    index: 2,
    category: "dental-firinlar",
    product: [
      {
        code: 1,
        id: "aYdRQamh7o",
        model: "CERAMIC MASTER E20",
        img: [e20_1, e20_2, e20_3],
      },
      {
        code: 1,
        id: "OJ14vR3cSM",
        model: "CERAMICMASTER E1000",
        img: [e1000_1, e1000_2, e1000_3],
      },
      { code: 1, id: "ZK14vR3cSM", model: "CERAMICMASTER E3000", img: [e3000_1, e3000_2, e3000_3] },
      {
        code: 3,
        id: "TsvMva0AMu",
        model: "CERAMICMASTER PRESS",
        img: [erress_1, erress_2, erress_3],
      },
      {
        code: 3,
        id: "gX0OVoBxIK",
        model: "CERAMICMASTER PRESS PRO",
        img: [presspro_2, presspro_1, presspro_3],
      },
      {
        code: 2,
        id: "Qv0OVoBxIK",
        model: "FIREMAGIC ET400",
        img: [firemagicer400_2, firemagicer400_1],
      },
      {
        code: 2,
        id: "nxwVeHnsqI",
        model: "LC CAST 600T",
        img: [lccast_3, lccast_1, lccast_2, lccast_4, lccast_5],
      },
      {
        id: "fS9WOxabf1",
        model: "PINPRECISE PP100",
        img: [pinprecise_1, pinprecise_2],
      },
      { code: 2, id: "us9Wpxabf1", model: "POLI AM", img: [polyam_1, polyam_2] },
      { code: 2, id: "u963sxabf1", model: "POLI MAX", img: [polymax_2, polymax_1] },
      {
        id: "zObKvzOCgk",
        model: "SLITPRECISE SP100",
        img: [slitprecise_1, slitprecise_2, slitprecise_3, slitprecise_4],
      },
      { code: 4, id: "AbbKvzOCgk", model: "ZIRCONMASTER S", img: [zms_1] },
    ],
  },
  {
    index: 3,
    category: "dis-uniteleri",
    product: [
      {
        id: "JqScIHy2mA",
        model: "Advance Askılı Diş Ünitesi",
        img: [aadu_1, aadu_2],
      },
      {
        id: "lKBwnEMjYG",
        model: "ADVANCE Askılı Diş Ünitesi A1",
        img: [aadu_a1_1, aadu_a1_2],
      },
      {
        id: "PnKlBZXX8A",
        model: "ADVANCE Askılı Diş Ünitesi A3",
        img: [aadu_a3_1, aadu_a3_2],
      },
      {
        id: "I74N2fI3Y8",
        model: "ADVANCE Kamçılı Diş Ünitesi",
        img: [akdu_1, akdu_2],
      },
      {
        id: "m0A99mC1Fk",
        model: "ADVANCE Kamçılı Diş Ünitesi A1",
        img: [akdu_a1_1],
      },
      {
        id: "uRnqC40euA",
        model: "ADVANCE Kamçılı Diş Ünitesi A3",
        img: [akdu_a3_1],
      },
      {
        id: "dxYoNv22oe",
        model: "Ameliyat Masaları",
        img: [am_1, am_2, am_3, am_4],
      },
      {
        id: "d8kYA19AkJ",
        model: "Jinekolojik ve Ürolojik Masa",
        img: [jvum_2, jvum_1],
      },
      {
        id: "6Xn6aML6Fq",
        model: "Phantom Dental Simülasyon Çalışma Masası",
        img: [pdscm_1, pdscm_2],
      },
      {
        id: "AvrFuUwAeb",
        model: "QUATTRO Askılı Diş Ünitesi",
        img: [qadu_1, qadu_2],
      },
      {
        id: "YNRlN6GndQ",
        model: "QUATTRO Kamçılı Diş Ünitesi",
        img: [qkdu_1, qkdu_2],
      },
    ],
  },
  {
    index: 4,
    category: "goruntulenme-sistemleri",
    product: [
      {
        id: "3ZTv5PyOQ4",
        model: "Panoramik",
        img: [grntlnm],
      },
      {
        id: "cTg3vb7U9Y",
        model: "Tomografi",
        img: [grntlnm],
      },
    ],
  },
  {
    index: 5,
    category: "klinik-dolaplari",
    product: [
      {
        id: "epnxnAvWlU",
        model: "Anemon 00",
        img: [anemon00_1, anemon00_2, anemon00_3],
      },
      {
        id: "2T1pEFn3YS",
        model: "ANEMON 04",
        img: [anemon04_1],
      },
      {
        id: "552Cyt4p01",
        model: "ANEMON 05",
        img: [anemon05_1, anemon05_2],
      },
      {
        id: "2xYBmwMbdT",
        model: "ANEMON 06",
        img: [anemon06_1, anemon06_2],
      },
      {
        id: "9VIP5Ei9QX",
        model: "ANEMON 07",
        img: [anemon07_1, anemon07_2],
      },
      {
        id: "WzVYkyUoD2",
        model: "AZURIT 01",
        img: [azurit_01, azurit_02],
      },
      {
        id: "Xi5k2aJ4ed",
        model: "AZURIT 02",
        img: [azurit02_1, azurit02_2],
      },
      {
        id: "hLqXePzk1g",
        model: "AZURIT 05",
        img: [azurit05_1, azurit05_2, azurit05_3],
      },
      {
        id: "7YfeKNbzL9",
        model: "KUVARS 01",
        img: [kuvars01_1, kuvars01_2],
      },
      {
        id: "AiJ5G4Zj0o",
        model: "KUVARS MOBİL",
        img: [kuvarsmobil_1, kuvarsmobil_2],
      },
      {
        id: "mDrVUOMGai",
        model: "MOBİL KABİN 02",
        img: [mobilkabin02_1, mobilkabin02_2],
      },
      {
        id: "BfOBwS5xTj",
        model: "MOBİL KABİN 03",
        img: [mobilkabin03_1, mobilkabin03_2],
      },
      {
        id: "Kw9eOocx1F",
        model: "MOBİL KABİN 04",
        img: [mobilkabin04_1, mobilkabin04_2],
      },
    ],
  },
  {
    index: 6,
    category: "laboratuvar-ekipmanlari",
    product: [
      {
        id: "r3etcIMTgJ",
        model: "DFM 75E",
        img: [dfm_2, dfm_1],
      },
      {
        id: "V7RSRfY4BO",
        model: "PINPRECISE PP100",
        img: [pinprecise_1, pinprecise_2],
      },
      {
        id: "SwCYBQQ3TM",
        model: "SLITPRECISE SP100",
        img: [slitprecise_2, slitprecise_1, slitprecise_3, slitprecise_4],
      },
    ],
  },
  {
    index: 7,
    category: "teknisyen-masalari",
    product: [
      {
        id: "1jmZFqoVSi",
        model: "Çift Teknisyen Çalışma Masası",
        img: [ctcm_1],
      },
      {
        id: "pW7rUCqbGJ",
        model: "Tekli Teknisyen Çalışma Masası",
        img: [ttcm_1],
      },
    ],
  },
  {
    index: 8,
    category: "sarf-malzemeleri",
    product: [
      {
        id: "lLcw6ysuXl",
        model: "Zirdent",
        img: [zirdent_4, zirdent_1, zirdent_2, zirdent_3, zirdent_5, zirdent_6],
      },
    ],
  },
];

export default Products;
